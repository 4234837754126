import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import ProfileService from "@/core/services/profile.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const REFRESH = "refresh";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const REFRESH_USER = "refreshUser";

const state = {
  errors: null,
  user: ProfileService.getProfile(),
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    // if (!state.user || Object.keys(state.user).length === 0) {
    //   state.user = ProfileService.getProfile();
    // }

    return state.user;
  },
  hasUserRights: (state) => (requiredRights) => {
    try {
      const userRights = state.user.role.rights;
      const hasRequiredRights = requiredRights.every((requiredRight) => {
        const userSectionRights = userRights.find(userRight => userRight.section === requiredRight.section);
        return userSectionRights && requiredRight.rights.every(right => userSectionRights[right]);
      });
    
      return hasRequiredRights;
    } catch (err) {
      console.warn(err);
      return false;
    }
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  /**
   * Query to refresh the current logged in user (+ its company information)
   */
  [REFRESH_USER](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("users", context.state.user.id)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data.user);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          const user = data.user;
          user.token = data.tokens.access.token;
          user.refreshToken = data.tokens.refresh.token;
          delete user.tokens;
          context.commit(SET_AUTH, data.user);
          resolve(data.user);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            context.commit(SET_ERROR, ["Identifiants invalides"]);
          } else {
            context.commit(SET_ERROR, [response.data.message]);
          }
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    const refreshToken = JwtService.getRefreshToken();
    if (refreshToken) {
      ApiService.post("auth/logout", { refreshToken });
    }
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, newUser) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/register", newUser)
        .then(({ data }) => {
          const user = data.user;
          user.token = data.tokens.access.token;
          user.refreshToken = data.tokens.refresh.token;
          delete user.tokens;
          context.commit(SET_AUTH, data.user);
          resolve(data.user);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
          reject(response.data);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    if (state.user.token && state.user.refreshToken) {
      JwtService.saveToken(state.user.token);
      JwtService.saveRefreshToken(state.user.refreshToken);
      delete state.user.token;
      delete state.user.refreshToken;
      ApiService.setHeader();
    }

    ProfileService.saveProfile(state.user);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    ProfileService.destroyProfile();
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
