<template>
  <router-view />
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.$root.$on("bv::modal::shown", () => {
      const modalForm = document.getElementById("modal-form");
      if (modalForm && modalForm.querySelector("input")) {
        modalForm.querySelector("input").focus();
      }

      if (document.getElementById("kt_wizard_v1")) {
        // Initialize form wizard
        const wizard = new KTWizard("kt_wizard_v1", {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        // Validation before going to next page
        wizard.on("beforeNext", function(/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        });

        // Change event
        wizard.on("change", function(/*wizardObj*/) {
          setTimeout(() => {
            KTUtil.scrollTop();
          }, 500);
        });
      }
    });
  },
};
</script>