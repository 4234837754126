const PROFILE_KEY = "user_profile";

export const getProfile = () => {
  const profile = window.localStorage.getItem(PROFILE_KEY);
  try {
      return JSON.parse(profile);
  } catch {
      return {};
  }
};

export const saveProfile = profile => {
  window.localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
};

export const destroyProfile = () => {
  window.localStorage.removeItem(PROFILE_KEY);
};

export default { getProfile, saveProfile, destroyProfile };
