import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.setHeader();

    //request interceptors for in progress toast feedback to user
    Vue.axios.interceptors.request.use(
      (request) => {
        request.config = {
          showToast: false,
          ...(request.config || {}),
        }

        if (request.config.showToast && request.config.requestToast) {
          request.config.requestToastId = Vue.$toast.info(
            request.config.requestToast.title || "Chargement en cours...",
            { id: "in-progress-modal" }
          );
        }
        return request;
      }
    );


    Vue.axios.interceptors.response.use((response) => {
      const config = response.config;
      if (config.requestToastId) {
        Vue.$toast.dismiss("in-progress-modal");
      }

      if (config.showToast && config.responseToast) {
        Vue.$toast.success(
          config.responseToast.title || "Action effectuée avec succès",
          {
            timeout: 4000,
          },
        );
      }

      return response
    }, (error) => {
      const originalRequest = error.config;
      
      if (error.response && error.response.config.requestToastId) {
        Vue.$toast.dismiss("in-progress-modal");
      }

      if (error.response && error.response.status === 404 && originalRequest.url.includes('entreprise.data')) {
        Vue.$toast.info(
          "Nous n'avons pas pu préremplir les informations",
          {
            timeout: 4000,
          },
        );
        return Promise.reject(error);
      }

      if (error.response && error.response.status === 401 && !originalRequest._retry
        && !originalRequest.url.includes('auth/')) {
        originalRequest._retry = true;
        const refreshToken = JwtService.getRefreshToken();
        return ApiService
          .post("/auth/refresh-tokens", { refreshToken })
          .then(({ data }) => {
            JwtService.saveToken(data.access.token);
            JwtService.saveRefreshToken(data.refresh.token);
            ApiService.setHeader();
            originalRequest.headers.Authorization = `Bearer ${data.access.token}`;
            return axios(originalRequest);
          });
      } else {
        Vue.$toast.error(
          error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Une erreur est survenue",
          {
            timeout: 4000,
          },
        );
      }

      return Promise.reject(error);
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {*}
   */
  post(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource);
  }
};

export default ApiService;
